<template>
  <div>
    <b-card>
      <!-- Queue -->
      <b-row>
        <b-col>
          <span>{{ lang("t_queue") }}</span>
          <v-select
            appendToBody
            class="mt-50"
            v-model="selected_merged_queues"
            :reduce="(option) => option"
            label="display_name"
            value="internal_name"
            :options="merged_queues"
            :placeholder="lang('t_queue')"
            multiple
            deselectFromDropdown
            :closeOnSelect="false"
          >
            <template #option="{ internal_name, _type }">
              <div class="d-flex align-items-center"></div>
              <span v-if="_type == 'queue'" class="" style="margin: 0">{{
                merged_queues.find((e) => e.internal_name == internal_name)
                  .display_name
              }}</span>
              <span
                v-b-tooltip.hover.bottom="
                  queue_groups
                    .find((e) => e.internal_name)
                    .queues.map((e) => e.display_name)
                    .join(' - ')
                "
                v-else
                class="font-weight-bolder"
                style="margin: 0"
                >{{
                  merged_queues.find((e) => e.internal_name == internal_name)
                    .display_name
                }}</span
              >
              <small v-if="_type == 'queue_group'" class="text-muted ml-1">
                {{ lang(`t_queueGroup`) }}</small
              >
            </template>
            <template #list-header>
              <div class="my-50 mx-50">
                <b-button
                  class="w-50"
                  size="sm"
                  variant="flat-secondary"
                  @click="
                    () => {
                      selected_merged_queues = merged_queues;
                      get_live_agent();
                      get_live_queue();
                    }
                  "
                >
                  <feather-icon size="14" icon="CheckCircleIcon" />
                  {{ lang("t_selectAll") }}
                </b-button>
                <b-button
                  class="w-50"
                  size="sm"
                  variant="flat-secondary"
                  @click="
                    () => {
                      selected_merged_queues = [];
                      get_live_agent();
                      get_live_queue();
                    }
                  "
                >
                  <feather-icon size="14" icon="XOctagonIcon" />
                  {{ lang("t_clear") }}
                </b-button>
              </div>
            </template>
          </v-select>
        </b-col>
      </b-row>
    </b-card>

    <b-row v-if="selected_queues.length > 0">
      <b-col lg="5" md="5" sm="12">
        <b-row>
          <b-col lg="7" md="7" sm="12">
            <b-card no-body class="mb-1">
              <b-card-body class="text-center pb-0">
                <span
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="
                    langs['AwaitingInteractionsCount'].description
                  "
                  class="d-block font-medium-5 font-weigt-bold"
                  >{{ langs["AwaitingInteractionsCount"].display_name }}</span
                >
                <span class="d-block font-large-2 font-weight-bolder">{{
                  total_queue.AwaitingInteractionsCount
                }}</span>
              </b-card-body>
              <!-- apex chart -->
              <vue-apex-charts
                class="mb-3 mx-5"
                id="budget-chart"
                type="line"
                height="35"
                :options="budgetChart.options"
                :series="[time_series['AwaitingInteractionsCount']]"
              />
              <b-row class="text-center mx-0">
                <b-col
                  cols="6"
                  class="border-top border-right d-flex align-items-between flex-column py-1"
                >
                  <b-card-text
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="
                      langs['AnsweredCount'].description
                    "
                    class="text-muted mb-0"
                  >
                    {{ langs["AnsweredCount"].display_name }}
                  </b-card-text>
                  <span class="font-weight-bolder mb-0 font-medium-5">
                    {{ total_queue.AnsweredCount }}
                  </span>
                </b-col>

                <b-col
                  cols="6"
                  class="border-top d-flex align-items-between flex-column py-1"
                >
                  <b-card-text
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="
                      langs['AbandonedCount'].description
                    "
                    class="text-muted mb-0"
                  >
                    {{ langs["AbandonedCount"].display_name }}
                  </b-card-text>
                  <span class="font-weight-bolder mb-0 font-medium-5">
                    {{ total_queue.AbandonedCount }}
                  </span>
                </b-col>
              </b-row>
            </b-card>
            <!-- ActiveInteractionsCount -->
            <b-card no-body class="mb-1">
              <b-card-body class="py-1 d-flex align-content-center">
                <b-avatar rounded="lg" :variant="`light-primary`" size="45">
                  <i class="font-medium-3 bi bi-telephone-inbound"></i>
                </b-avatar>
                <div class="align-content-center ml-2">
                  <span class="d-block font-weight-bolder font-medium-3">
                    {{ total_queue.ActiveInteractionsCount }}
                  </span>
                  <span
                    class="d-block"
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="
                      langs['ActiveInteractionsCount'].description
                    "
                    >{{ langs["ActiveInteractionsCount"].display_name }}</span
                  >
                </div>
              </b-card-body>
            </b-card>
            <!-- AverageWaitTime -->
            <b-card no-body class="mb-1">
              <b-card-body class="py-1 d-flex align-content-center">
                <b-avatar rounded="lg" :variant="`light-primary`" size="45">
                  <i class="font-medium-3 bi bi-hourglass-split"></i>
                </b-avatar>
                <div class="align-content-center ml-2">
                  <span class="d-block font-weight-bolder font-medium-3">
                    {{ secondsToMMSS(total_queue.AverageWaitTime) }}
                  </span>
                  <span
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="
                      langs['AverageWaitTime'].description
                    "
                    class="d-block"
                    >{{ langs["AverageWaitTime"].display_name }}</span
                  >
                </div>
              </b-card-body>
            </b-card>
            <!-- CallBackSuccess -->
            <b-card no-body class="mb-1">
              <b-card-body class="py-1 d-flex align-content-center">
                <b-avatar rounded="lg" :variant="`light-primary`" size="45">
                  <i class="font-medium-3 bi bi-telephone-outbound"></i>
                </b-avatar>
                <div class="align-content-center ml-2">
                  <span class="d-block font-weight-bolder font-medium-3">
                    {{ total_queue.CallBackSuccess }}
                  </span>
                  <span
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="
                      langs['CallBackSuccess'].description
                    "
                    class="d-block"
                    >{{ langs["CallBackSuccess"].display_name }}</span
                  >
                </div>
              </b-card-body>
            </b-card>
            <!-- FirstCallResolutionCount -->
            <b-card no-body class="mb-0">
              <b-card-body class="py-1 d-flex align-content-center">
                <b-avatar rounded="lg" :variant="`light-primary`" size="45">
                  <i class="font-medium-3 bi bi-check-lg"></i>
                </b-avatar>
                <div class="align-content-center ml-2">
                  <span class="d-block font-weight-bolder font-medium-3">
                    {{ total_queue.FirstCallResolutionCount }}
                  </span>
                  <span
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="
                      langs['FirstCallResolutionCount'].description
                    "
                    class="d-block"
                    >{{ langs["FirstCallResolutionCount"].display_name }}</span
                  >
                </div>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col lg="5" md="5" sm="12">
            <!-- SERVICE LEVEL -->
            <b-row>
              <b-col class="pl-0" sm="12" md="12" lg="12">
                <b-card no-body style="height: 10.5rem" class="mb-1">
                  <b-card-body class="pb-0">
                    <b-avatar
                      rounded="lg"
                      class="mb-1"
                      :variant="`light-primary`"
                      size="45"
                    >
                      <i class="font-medium-3 bi bi-bar-chart"></i>
                    </b-avatar>
                    <div class="float-right">
                      <span
                        class="d-block font-weight-bolder text-right font-medium-3"
                      >
                        {{ total_queue.ServiceLevel }}
                      </span>
                      <span
                        v-b-tooltip.hover.v-secondary
                        v-b-tooltip.hover.bottom="
                          langs['ServiceLevel'].description
                        "
                        >{{ langs["ServiceLevel"].display_name }}</span
                      >
                    </div>
                  </b-card-body>

                  <vue-apex-charts
                    class="mt-75"
                    type="area"
                    height="54"
                    width="100%"
                    :options="areaChartOptions"
                    :series="[time_series['ServiceLevel']]"
                  />
                </b-card>
              </b-col>
            </b-row>
            <!-- SUCCESS RATE -->
            <b-row>
              <b-col class="pl-0" sm="12" md="12" lg="12">
                <b-card no-body style="height: 10.5rem" class="mb-1">
                  <b-card-body class="pb-0">
                    <b-avatar
                      rounded="lg"
                      class="mb-1"
                      :variant="`light-primary`"
                      size="45"
                    >
                      <i class="font-medium-3 bi bi-patch-check"></i>
                    </b-avatar>
                    <div class="float-right">
                      <span
                        class="d-block font-weight-bolder text-right font-medium-3"
                      >
                        {{ total_queue.SRLevel }}
                      </span>
                      <span
                        v-b-tooltip.hover.v-secondary
                        v-b-tooltip.hover.bottom="langs['SRLevel'].description"
                        >{{ langs["SRLevel"].display_name }}</span
                      >
                    </div>
                  </b-card-body>

                  <vue-apex-charts
                    class="mt-75"
                    type="area"
                    height="54"
                    width="100%"
                    :options="areaChartOptions"
                    :series="[time_series['SRLevel']]"
                  />
                </b-card>
              </b-col>
            </b-row>
            <!-- AVG CALL TIME -->
            <b-row>
              <b-col class="pl-0" sm="12" md="12" lg="12">
                <b-card no-body style="height: 10.5rem" class="mb-1">
                  <b-card-body class="pb-0">
                    <b-avatar
                      rounded="lg"
                      class="mb-1"
                      :variant="`light-primary`"
                      size="45"
                    >
                      <i class="font-medium-3 bi bi-stopwatch"></i>
                    </b-avatar>
                    <div class="float-right">
                      <span
                        class="d-block font-weight-bolder text-right font-medium-3"
                      >
                        {{ secondsToMMSS(total_queue.AverageConnectedTime) }}
                      </span>
                      <span
                        v-b-tooltip.hover.v-secondary
                        v-b-tooltip.hover.bottom="
                          langs['AverageConnectedTime'].description
                        "
                        >{{ langs["AverageConnectedTime"].display_name }}</span
                      >
                    </div>
                  </b-card-body>

                  <vue-apex-charts
                    class="mt-75"
                    type="area"
                    height="54"
                    width="100%"
                    :options="areaChartOptions"
                    :series="[time_series['AverageConnectedTime']]"
                  />
                </b-card>
              </b-col>
            </b-row>
            <!-- ABANDON RATE -->
            <b-row>
              <b-col class="pl-0" sm="12" md="12" lg="12">
                <b-card no-body style="height: 10.75rem" class="mb-0">
                  <b-card-body class="pb-0">
                    <b-avatar
                      rounded="lg"
                      class="mb-1"
                      :variant="`light-primary`"
                      size="45"
                    >
                      <i class="font-medium-3 bi bi-telephone-x"></i>
                    </b-avatar>
                    <div class="float-right">
                      <span
                        class="d-block font-weight-bolder text-right font-medium-3"
                      >
                        {{ total_queue.AbandonRate }}
                      </span>
                      <span
                        v-b-tooltip.hover.v-secondary
                        v-b-tooltip.hover.bottom="
                          langs['AbandonRate'].description
                        "
                        >{{ langs["AbandonRate"].display_name }}</span
                      >
                    </div>
                  </b-card-body>

                  <vue-apex-charts
                    class="mt-75"
                    type="area"
                    height="54"
                    width="100%"
                    :options="areaChartOptions"
                    :series="[time_series['AbandonRate']]"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        class="pl-0"
        style="max-height: 45rem; overflow-y: auto"
        lg="7"
        md="7"
        sm="12"
      >
        <b-card
          v-for="(item, i) in Object.values(queue_list).sort(function (a, b) {
            return b.AwaitingInteractionsCount - a.AwaitingInteractionsCount;
          })"
          no-body
          class="mb-50"
          :key="i"
          :id="item.Queue"
        >
          <b-avatar
            style="bottom:0; position: absolute; left: 0px; z-index-9999"
            size="sm"
            v-if="item.AwaitingInteractionsCount > 0"
            class="mr-1"
            rounded="sm"
            variant="light-secondary"
          >
            <span> {{ item.AwaitingInteractionsCount }}</span>
          </b-avatar>
          <b-avatar
            v-b-tooltip.hover.v-success
            v-b-tooltip.hover.bottom="lang('t_activeInteractionsCount')"
            style="bottom:0; position: absolute; right: 0px; z-index-9999"
            size="sm"
            v-if="item.ActiveInteractionsCount > 0"
            rounded="sm"
            variant="light-success"
          >
            <span> {{ item.ActiveInteractionsCount }}</span>
          </b-avatar>
          <b-card-body class="py-50">
            <b-row class="d-flex justify-content-center" align-h="between">
              <b-col class="text-truncate">
                <div class="pl-1">
                  <div class="d-block text-center">
                    <!-- <b-badge class="py-25" variant="light-primary">
                      <span
                        class="d-block font-weight-bolder font-medium-1 text-center mb-25"
                      >
                   
                      </span>
                    </b-badge> -->
                    {{
                      queues.find((e) => e.internal_name == item.Queue)
                        ? queues.find((e) => e.internal_name == item.Queue)
                            .display_name
                        : item.Queue
                    }}
                  </div>

                  <div class="d-flex align-items-center justify-content-center">
                    <span
                      v-b-tooltip.hover.v-success
                      v-b-tooltip.hover.bottom="lang('t_answered')"
                      class="text-success"
                    >
                      <i class="bi bi-telephone-inbound"></i>
                      {{ item.AnsweredCount }}</span
                    >
                    <span
                      v-b-tooltip.hover.v-danger
                      v-b-tooltip.hover.bottom="lang('t_abandoned')"
                      class="text-danger ml-2"
                    >
                      <i class="bi bi-telephone-x"></i>
                      {{ item.AbandonedCount }}</span
                    >
                  </div>
                </div>

                <!-- <span
                  class="d-block font-weight-bolder font-medium-1 text-truncate"
                  >{{
                    queues.find((e) => e.internal_name == item.Queue)
                      ? queues.find((e) => e.internal_name == item.Queue)
                          .display_name
                      : item.Queue
                  }}</span
                > -->

                <!-- <span class="d-block text-truncate">Açıklama</span> -->
              </b-col>
              <b-col>
                <div class="d-flex justify-content-center align-items-center">
                  <b-avatar class="mr-1" rounded="lg" variant="light-primary">
                    <i class="font-medium-1 bi bi-people"></i>
                  </b-avatar>
                  <div>
                    <span class="d-block font-weight-bolder font-medium-1">
                      {{ item.Agent ? item.Agent.Total : "0" }}</span
                    >
                    <span class="d-block"> {{ lang("t_total") }}</span>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex justify-content-center align-items-center">
                  <b-avatar class="mr-1" rounded="lg" variant="light-success">
                    <i class="font-medium-1 bi bi-person"></i>
                  </b-avatar>
                  <div>
                    <span class="d-block font-weight-bolder font-medium-1">
                      {{ item.Agent ? item.Agent.Available : "0" }}</span
                    >
                    <span class="d-block"> {{ lang("t_available") }}</span>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex justify-content-center align-items-center">
                  <b-avatar class="mr-1" rounded="lg" variant="light-info">
                    <i class="font-medium-1 bi bi-headset"></i>
                  </b-avatar>
                  <div>
                    <span class="d-block font-weight-bolder font-medium-1">
                      {{ item.Agent ? item.Agent.InInteraction : "0" }}</span
                    >
                    <span class="d-block"> {{ lang("t_inCall") }}</span>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex justify-content-center align-items-center">
                  <b-avatar class="mr-1" rounded="lg" variant="light-warning">
                    <i class="font-medium-1 bi bi-cup-hot"></i>
                  </b-avatar>
                  <div>
                    <span class="d-block font-weight-bolder font-medium-1">
                      {{ item.Agent ? item.Agent.Paused : 0 }}</span
                    >
                    <span class="d-block"> {{ lang("t_paused") }}</span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BButton,
  BBadge,
  BContainer,
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardBody,
  BCardFooter, BCardTitle,
  BCardSubTitle,
  BCardHeader, VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
var audio = new Audio("sounds/ding.mp3");
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardText,
    BBadge,
    vSelect,
    BButton,
    BCardBody,
    BCardFooter, BCardTitle,
    BCardSubTitle,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  watch: {
    selected_merged_queues(newValue, oldValue) {
      console.log("newValue", newValue);
      this.selected_queues = [];
      let q_set = new Set();
      for (const row of newValue) {
        if (row._type == 'queue_group') {
          for (const _queue of row.queues) {
            q_set.add(_queue.internal_name);
          }
        } else {
          q_set.add(row.internal_name);
        }
      }
      this.selected_queues = Array.from(q_set);
      console.log("selected_queues", this.selected_queues);
      this.getLiveQueue();
      this.getCallBackSuccessCount();
    }
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      queue_list: Object.create(null),
      all_queues: [],
      queues: [],
      total_queue: {},
      time_series: {
        AwaitingInteractionsCount: { name: 'Last7Min', data: [0, 0, 0, 0, 0, 0, 0] },
        ServiceLevel: { name: 'Last7Min', data: [0, 0, 0, 0, 0, 0, 0] },
        SRLevel: { name: 'Last7Min', data: [0, 0, 0, 0, 0, 0, 0] },
        AverageConnectedTime: { name: 'Last7Min', data: [0, 0, 0, 0, 0, 0, 0] },
        AbandonRate: { name: 'Last7Min', data: [0, 0, 0, 0, 0, 0, 0] },
      },
      selected_merged_queues: [],
      merged_queues: [],
      selected_queues: [],
      queue_groups: [],
      selected_queue_group: [],
      interval1: {},
      interval2: {},
      interval3: {},
      langs: {
        "AwaitingInteractionsCount": { display_name: globalThis._lang('t_awaitingInteractionsCount'), description: globalThis._lang('t_descawaitingInteractionsCount') },
        "AnsweredCount": { display_name: globalThis._lang('t_answeredCount'), description: globalThis._lang('t_descansweredCount') },
        "AbandonedCount": { display_name: globalThis._lang('t_abandonedCount'), description: globalThis._lang('t_descabandonedCount') },
        "ActiveInteractionsCount": { display_name: globalThis._lang('t_activeInteractionsCount'), description: globalThis._lang('t_descactiveInteractionsCount') },
        "AverageWaitTime": { display_name: globalThis._lang('t_averageWaitTime'), description: globalThis._lang('t_descaverageWaitTime') },
        "CallBackSuccess": { display_name: globalThis._lang('t_callBackSuccess'), description: globalThis._lang('t_desccallBackSuccess') },
        "FirstCallResolutionCount": { display_name: globalThis._lang('t_firstCallResolutionCount'), description: globalThis._lang('t_descfirstCallResolutionCount') },
        "ServiceLevel": { display_name: globalThis._lang('t_serviceLevel'), description: globalThis._lang('t_descserviceLevel') },
        "SRLevel": { display_name: globalThis._lang('t_sRLevel'), description: globalThis._lang('t_descsRLevel') },
        "AverageConnectedTime": { display_name: globalThis._lang('t_averageConnectedTime'), description: globalThis._lang('t_descaverageConnectedTime') },
        "AbandonRate": { display_name: globalThis._lang('t_abandonRate'), description: globalThis._lang('t_descabandonRate') },
      },
      budgetChart: {
        options: {
          chart: {
            height: 35,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            sparkline: { enabled: true },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.primary, '#dcdae3'],
          tooltip: {
            enabled: true,
          },
        },
      },
      series: [
        {
          name: 'Last7Min',
          data: [12, 32, 21, 12, 52]
        },

      ],
      areaChartOptions: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2.5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.9,
            opacityFrom: 0.5,
            opacityTo: 0.2,
            stops: [0, 80, 100],
          },
        },
        xaxis: {
          type: 'numeric',
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: { show: false },
        },
        yaxis: [
          {
            y: 0,
            offsetX: 0,
            offsetY: 0,
            padding: {
              left: 0,
              right: 0,
            },
          },
        ],
        tooltip: {
          x: { show: false },
        },
        theme: {
          monochrome: {
            enabled: true,
            color: $themeColors.primary,
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
      },
      visible_users: {},
    };
  },
  methods: {
    get_users: async function () {
      var response = (await this.$http_in.get(`spv/v1/User`)).data;
      for (const row of response) {
        this.visible_users[row.identifier] = row.is_visible_in_live_watch;
      }
    },
    InteractionJoinedQueue(data) {
      var element = document.getElementById(data.Queue);
      element.classList.add('border-success');
      setTimeout(function () {
        element.classList.remove('border-success');
      }, 800);
    },
    InteractionAbandoned(data) {
      var element = document.getElementById(data.Queue);
      element.classList.add('border-danger');
      setTimeout(function () {
        element.classList.remove('border-danger');
      }, 800);
    },
    async getCallBackSuccessCount() {

      var response = (await this.$http_in.get(`qmgr/v1/LiveQueue`)).data;
      var interaction_details_data = [];

      for (const queue of response) {
        if (this.selected_queues.includes(queue.Name)) {
          for (const item of queue.AbandonedInteractionsList.filter(
            (e) => e.QueueWaitTime != null
          )) {
            interaction_details_data.push({
              queue: item.Details.Queue,
              timestamp: new Date(item.AbandonTime).getTime(),
              date: new Date(item.AbandonTime + 10800000)
                .toISOString()
                .replace("T", " ")
                .replace("Z", ""),
              phone: item.Details.RemoteNumber.substr(-10),
              time: item.QueueWaitTime || 0,
            });
          }

        }
      }

      var abandoned_list = (
        await this.$http_in.post(`spv/v1/AbandonCallDetails`, {
          phone_list: interaction_details_data.map((e) => e.phone),
        })
      ).data;
      for (const item of interaction_details_data) {
        console.log("item", item);
        let tmp = abandoned_list.filter(
          (e) =>
            e.StartTime > item.timestamp && e.BaseRemoteNumber == item.phone
        );
        console.log("tmp", tmp);
        item.is_reached = tmp.length > 0 ? true : false;
      }

      this.total_queue.CallBackSuccess = interaction_details_data.filter(e => e.is_reached).length;

    },
    secondsToMMSS(seconds) {
      const totalSeconds = Math.round(seconds);

      const minutes = Math.floor(totalSeconds / 60);

      const remainingSeconds = totalSeconds % 60;

      const paddedMinutes = String(minutes).padStart(2, '0');
      const paddedSeconds = String(remainingSeconds).padStart(2, '0');

      // mm:ss formatında sonucu döndür
      return `${paddedMinutes}:${paddedSeconds}`;
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      let p_queues = globalThis.permissions["queue"];
      let default_push = this.selected_queues.length > 0 ? false : true;
      this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
          this.merged_queues.push({
            ...item,
            _type: 'queue'
          })

          if (default_push) {
            this.selected_merged_queues.push({ ...item, _type: 'queue' });
            // this.selected_queues.push(item.internal_name);
          }
        }
      }
    },
    GetQueueGroup: async function () {
      var response = (await this.$http_in.get(`/spv/v1/QueueGroup`)).data;
      // console.log('queues', response);
      // let p_queues = globalThis.permissions["queue"]

      this.queue_groups = response;
      let _queue_group = response.map(function (obj) {
        return { ...obj, _type: "queue_group" };
      });
      this.merged_queues = [...this.merged_queues, ..._queue_group];


      // for (const item of response) {
      //   if (p_queues.includes(item.internal_name)) {
      //     this.queues.push(item);
      //     this.selected_queues.push(item.internal_name);
      //   }
      // }
    },
    queue_group_changed() {
      // console.log("queue_group_changed", this.selected_queue_group)
      let queues = [];
      for (const item of this.selected_queue_group) {
        queues = [...queues, ...item.queues.map((e) => e.internal_name)];
      }
      // console.log("queues", queues)

      this.selected_queues = Array.from(new Set([...this.selected_queues, ...queues]));
      this.getLiveQueue();
      this.getCallBackSuccessCount();
    },
    calculateAverages(dataObj) {
      const keys = Object.keys(dataObj);
      const metrics = Object.keys(dataObj[keys[0]]);
      const averages = {};

      metrics.forEach(metricKey => {
        const sumData = new Array(dataObj[keys[0]][metricKey].data.length).fill(0);
        let count = 0;

        keys.forEach(queueKey => {
          const metricData = dataObj[queueKey][metricKey].data;
          if (metricData.length > 0) {
            count++;
            metricData.forEach((value, index) => {
              sumData[index] += value;
            });
          }
        });

        let averageData = [];
        // console.log("dataObj[keys[0]][metricKey].name", dataObj[keys[0]][metricKey].name)
        if (dataObj[keys[0]][metricKey].name == 'Ortalama Çağrı Süresi') {
          averageData = sumData.map(total => this.secondsToMMSS(parseFloat(total / count).toFixed(2)));

        } else {
          averageData = sumData.map(total => parseFloat(total / count).toFixed(2));
        }

        averages[metricKey] = {
          name: dataObj[keys[0]][metricKey].name,
          data: averageData
        };
      });

      return averages;
    },
    getLiveQueueTimeSeries: async function () {
      const response = (await this.$http_in.get(`/qmgr/v1/QueueTimeSeries/7`)).data;
      return response;
    },
    getLiveQueue: async function () {
      if (this.selected_queues.length == 0) {
        return;
      }
      var response = (await this.$http_in.get(`qmgr/v1/LiveQueueStatistics`)).data;
      var _data = await this.getLiveQueueTimeSeries();
      console.log("_data", _data)
      let _tmp_time_series = {};
      for (const queue of _data) {
        console.log("queue.Name", queue.Name);
        if (this.selected_queues.includes(queue.Name)) {
          _tmp_time_series[queue.Name] = {

            "ActiveInteractionsCount": {
              name: globalThis._lang('t_activeInteractionsCount'),
              data: queue.TimeSeries.map(e => e.ActiveInteractionsCount),
            },
            "AwaitingInteractionsCount": {
              name: globalThis._lang('t_awaitingInteractionsCount'),
              data: queue.TimeSeries.map(e => e.AwaitingInteractionsCount),
            },
            "AnsweredCount": {
              name: globalThis._lang('t_answered'),
              data: queue.TimeSeries.map(e => e.AnsweredCount),
            },
            "AbandonedCount": {
              name: globalThis._lang('t_abandoned'),
              data: queue.TimeSeries.map(e => e.AbandonedCount),
            },
            "TotalInteractionsCount": {
              name: 'Last7Min',
              data: queue.TimeSeries.map(e => e.TotalInteractionsCount),
            },
            "AbandonRate": {
              name: globalThis._lang('t_abandonRate'),
              data: queue.TimeSeries.map(e => e.AbandonRate),
            },
            "ServiceLevel": {
              name: globalThis._lang('t_serviceLevel'),
              data: queue.TimeSeries.map(e => e.ServiceLevel),
            },
            "AnsweredBeforeSL": {
              name: 'Last7Min',
              data: queue.TimeSeries.map(e => e.AnsweredBeforeSL),
            },
            "FinishCodeSuccessCalls": {
              name: 'Last7Min',
              data: queue.TimeSeries.map(e => e.FinishCodeSuccessCalls),
            },
            "SRLevel": {
              name: globalThis._lang('t_sRLevel'),
              data: queue.TimeSeries.map(e => e.SRLevel),
            },
            "AverageConnectedTime": {
              name: globalThis._lang('t_averageConnectedTime'),
              data: queue.TimeSeries.map(e => e.AverageConnectedTime),
            },
            "AverageWaitTime": {
              name: globalThis._lang('t_averageWaitTime'),
              data: queue.TimeSeries.map(e => e.AverageWaitTime),
            },
            "FirstCallResolutionCount": {
              name: globalThis._lang('t_firstCallResolutionCount'),
              data: queue.TimeSeries.map(e => e.FirstCallResolutionCount),
            },
          }
        }

      }
      console.log('_tmp_time_series', _tmp_time_series);

      let _tmp_queue_list = Object.create(null);
      let total_calls = {
        TotalInteractionsCount: 0,
        AnsweredCount: 0,
        AwaitingInteractionsCount: 0,
        AbandonedCount: 0,
        AbandonRate: 0,
        ActiveInteractionsCount: 0,
        AnsweredBeforeSL: 0,
        AverageConnectedTime: 0,
        AverageWaitTime: 0,
        FirstCallResolutionCount: 0,
        FinishCodeSuccessCalls: 0,
        SRLevel: 0,
        ServiceLevel: 0,
        TotalInteractionsCount: 0,
      };

      // _tmp_queue_list[globalThis._lang("t_total")] = {
      //   Queue: globalThis._lang("t_total"),
      //   TotalInteractionsCount: total_calls.TotalInteractionsCount,
      //   AnsweredCount: total_calls.AnsweredCount,
      //   AwaitingInteractionsCount: total_calls.AwaitingInteractionsCount,
      //   AbandonedCount: total_calls.AbandonedCount,
      //   Agent: {
      //     Total: 0,
      //     Available: 0,
      //     Paused: 0,
      //     InInteraction: 0,
      //   },
      // };

      let q_count = 0;
      for (const item of response.filter(e => e.Statistics.TotalInteractionsCount > 0 || e.Statistics.AwaitingInteractionsCount > 0)) {
        if (this.selected_queues.includes(item.Name)) {
          q_count++;
          _tmp_queue_list[item.Name] = {
            Queue: item.Name,
            TotalInteractionsCount: item.Statistics.TotalInteractionsCount,
            AnsweredCount: item.Statistics.AnsweredCount,
            AwaitingInteractionsCount: item.Statistics.AwaitingInteractionsCount,
            AbandonedCount: item.Statistics.AbandonedCount,
            AbandonRate: item.Statistics.AbandonRate,
            ActiveInteractionsCount: item.Statistics.ActiveInteractionsCount,
            AnsweredBeforeSL: item.Statistics.AnsweredBeforeSL,
            Agent: this.queue_list[item.Name] ? this.queue_list[item.Name].Agent : {
              Total: 0,
              Available: 0,
              Paused: 0,
              InInteraction: 0,
            },
            AverageConnectedTime: item.Statistics.AverageConnectedTime,
            AverageWaitTime: item.Statistics.AverageWaitTime,
            FirstCallResolutionCount: item.Statistics.FirstCallResolutionCount,
            FinishCodeSuccessCalls: item.Statistics.FinishCodeSuccessCalls,
            SRLevel: item.Statistics.SRLevel,
            ServiceLevel: item.Statistics.ServiceLevel,
            TotalInteractionsCount: item.Statistics.TotalInteractionsCount,
          };

          total_calls.TotalInteractionsCount += Math.round(item.Statistics.TotalInteractionsCount);
          total_calls.AnsweredCount += Math.round(item.Statistics.AnsweredCount);
          total_calls.AwaitingInteractionsCount += Math.round(item.Statistics.AwaitingInteractionsCount);
          total_calls.AbandonedCount += Math.round(item.Statistics.AbandonedCount);
          total_calls.AbandonRate += Math.round(item.Statistics.AbandonRate);
          total_calls.ActiveInteractionsCount += Math.round(item.Statistics.ActiveInteractionsCount);
          total_calls.AnsweredBeforeSL += Math.round(item.Statistics.AnsweredBeforeSL);
          total_calls.AverageConnectedTime += Math.round(item.Statistics.AverageConnectedTime);
          total_calls.AverageWaitTime += Math.round(item.Statistics.AverageWaitTime);
          total_calls.FirstCallResolutionCount += Math.round(item.Statistics.FirstCallResolutionCount);
          total_calls.FinishCodeSuccessCalls += Math.round(item.Statistics.FinishCodeSuccessCalls);
          total_calls.SRLevel += Math.round(item.Statistics.SRLevel);
          total_calls.ServiceLevel += Math.round(item.Statistics.ServiceLevel);
          total_calls.TotalInteractionsCount += Math.round(item.Statistics.TotalInteractionsCount);
        }
      }

      // _tmp_queue_list[globalThis._lang("t_total")] = {
      //   Queue: globalThis._lang("t_total"),
      //   TotalInteractionsCount: total_calls.TotalInteractionsCount,
      //   AnsweredCount: total_calls.AnsweredCount,
      //   AwaitingInteractionsCount: total_calls.AwaitingInteractionsCount,
      //   AbandonedCount: total_calls.AbandonedCount,
      //   AbandonRate: total_calls.AbandonRate,
      //   ActiveInteractionsCount: total_calls.ActiveInteractionsCount,
      //   AnsweredBeforeSL: total_calls.AnsweredBeforeSL,
      //   AverageConnectedTime: total_calls.AverageConnectedTime,
      //   AverageWaitTime: total_calls.AverageWaitTime,
      //   FirstCallResolutionCount: total_calls.FirstCallResolutionCount,
      //   FinishCodeSuccessCalls: total_calls.FinishCodeSuccessCalls,
      //   SRLevel: total_calls.SRLevel,
      //   ServiceLevel: total_calls.ServiceLevel,
      //   TotalInteractionsCount: total_calls.TotalInteractionsCount
      // };
      total_calls.AbandonRate = isNaN(Math.round(total_calls.AbandonRate / q_count)) ? 0 : Math.round(total_calls.AbandonRate / q_count);
      total_calls.AnsweredBeforeSL = isNaN(Math.round(total_calls.AnsweredBeforeSL / q_count)) ? 0 : Math.round(total_calls.AnsweredBeforeSL / q_count);
      total_calls.AverageConnectedTime = isNaN(Math.round(total_calls.AverageConnectedTime / q_count)) ? 0 : Math.round(total_calls.AverageConnectedTime / q_count);
      total_calls.AverageWaitTime = isNaN(Math.round(total_calls.AverageWaitTime / q_count)) ? 0 : Math.round(total_calls.AverageWaitTime / q_count);
      total_calls.SRLevel = isNaN(Math.round(total_calls.SRLevel / q_count)) ? 0 : Math.round(total_calls.SRLevel / q_count);
      total_calls.ServiceLevel = isNaN(Math.round(total_calls.ServiceLevel / q_count)) ? 0 : Math.round(total_calls.ServiceLevel / q_count);

      this.queue_list = _tmp_queue_list;
      this.total_queue = { ...this.total_queue, ...total_calls };
      this.time_series = { ...this.calculateAverages(_tmp_time_series) };
      console.log("this.time_series", this.time_series);
    },
    get_live_agent: async function () {
      var response = (await this.$http_in.get(`qmgr/v1/LiveAgent`)).data;
      // console.log("get_live_agent", response);
      this.agents = response;

      let total_agents = {
        Total: new Set(),
        Available: new Set(),
        Paused: new Set(),
        InInteraction: new Set(),
      };

      let _tmp_queue_list = {}



      for (const row of response) {
        console.log(row.Identifier, this.visible_users[row.Identifier]);
        if (!this.visible_users[row.Identifier]) {
          continue;
        }
        for (const queue of row.Queues.Value.filter(e => e != null)) {
          if (!_tmp_queue_list[queue]) {
            _tmp_queue_list[queue] = {
              Total: 0,
              Available: 0,
              Paused: 0,
              InInteraction: 0,
            }
          }

          // console.log(row.Username, row.Status, queue)
          if (this.selected_queues.includes(queue) && this.selected_queues.length > 0 && this.queue_list[queue]) {
            if (row.Status == "Available") {
              _tmp_queue_list[queue].Available++;
            }
            if (row.Status == "Paused") {
              let __statu = globalThis.statuses.find(e => e.display_name == row.Reason);

              if (__statu) {
                let __category = globalThis.status_categories.find(e => e._id == __statu.status_category_id);
                if (__category && ['Mola', 'Break'].includes(__category.display_name)) {
                  _tmp_queue_list[queue].Paused++;
                }
              }
            }
            if (row.Status == "InInteraction") {
              _tmp_queue_list[queue].InInteraction++;
            }
            _tmp_queue_list[queue].Total++;
          }
        }
      }
      // this.queue_list[globalThis._lang("t_total")].Agent = {
      //   Total: total_agents.Total.size,
      //   Available: total_agents.Available.size,
      //   Paused: total_agents.Paused.size,
      //   InInteraction: total_agents.InInteraction.size,
      // }

      console.log("this.queue_list", this.queue_list);
      for (const _queue of Object.keys(_tmp_queue_list)) {
        if (this.queue_list[_queue]) {
          this.queue_list[_queue] = {
            ...this.queue_list[_queue],
            Agent: _tmp_queue_list[_queue]
          }
        }
      }
      // this.queue_list = { ...this.queue_list };
    },

  },
  mounted: async function () {
    let spvQueues = JSON.parse(localStorage.getItem("spvQueues"));
    if (![undefined, null].includes(spvQueues)) {
      let _data = spvQueues.find((e) => e.username == globalThis.username);
      if (![undefined, null].includes(_data)) {
        // this.selected_queues = _data.selected_queues;
        // this.selected_queue_group = _data.selected_queue_group;
        this.selected_merged_queues = _data.selected_merged_queues || [];
      }
    }

    await this.get_users();
    await this.GetQueues();
    await this.GetQueueGroup();
    await this.get_live_agent();
    await this.getLiveQueue();

    await this.getCallBackSuccessCount();
    globalThis.wallboard = this;

    const _self = this;


    _self.interval1 = setInterval(async () => {
      await globalThis.wallboard.getCallBackSuccessCount();
    }, 60000 * 5);
    _self.interval2 = setInterval(async () => {
      await globalThis.wallboard.get_live_agent();
      await globalThis.wallboard.getLiveQueue();
    }, 2000);

    _self.interval3 = setInterval(async () => {

      if (wallboard.queue_list.length > 0) {
        for (const key of Object.keys(wallboard.queue_list)) {
          if (_self.queue_list[key].AwaitingInteractionsCount >= 1) {
            audio.play();
          }
        }
      }


    }, 5000);
  },
  beforeDestroy() {


    let _spvQueues = JSON.parse(localStorage.getItem("spvQueues"));
    if (![undefined, null].includes(_spvQueues)) {
      let _data = _spvQueues.find((e) => e.username == globalThis.username);
      if (![undefined, null].includes(_data)) {
        _data.selected_merged_queues = this.selected_merged_queues;
      } else {
        _spvQueues.push({
          username: globalThis.username,
          selected_merged_queues: this.selected_merged_queues,
        });
      }
    } else {
      _spvQueues = [];
      _spvQueues.push({
        username: globalThis.username,
        selected_merged_queues: this.selected_merged_queues,
      });
    }

    localStorage.setItem("spvQueues", JSON.stringify(_spvQueues));
  },
  destroyed() {
    clearInterval(this.interval1);
    clearInterval(this.interval2);
    clearInterval(this.interval3);
  }
};
</script>

<style></style>
